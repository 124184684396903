import PropTypes from 'prop-types';
import classNames from 'classnames';

import './DashboardNav.scss';


export default function makeDashboardNav(React) {
  const DashboardNav = ({
    leftButtons,
    rightButtons,
    NavLink,
  }) => {
    const renderNavLink = ({
      id, // eslint-disable-line react/prop-types
      path, // eslint-disable-line react/prop-types
      title, // eslint-disable-line react/prop-types
      shortTitle, // eslint-disable-line react/prop-types
    }) => (
      <NavLink
        key={id}
        to={path}
        className={classNames(
          'MenuButton',
          `MenuButton--${id}`,
          'rc-nav-menu-item',
        )}
        activeClassName="rc-nav-menu-item--active"
        exact
      >
        <span className="MenuButton__icon" />
        <span className="MenuButton__title">
          {title}
        </span>
        <span className="MenuButton__short-title">
          {shortTitle}
        </span>
      </NavLink>
    );

    return (
      <div className="Header__menu">
        <div className="Page__width Header__menu__buttons">
          {leftButtons && leftButtons.length > 0 && (
            <div className="Header__menu__buttons__left">
              {leftButtons.map(renderNavLink)}
            </div>
          )}
          {rightButtons && rightButtons.length > 0 && (
            <div className="Header__menu__buttons__right">
              {rightButtons.map(renderNavLink)}
            </div>
          )}
        </div>
      </div>
    );
  };

  const navItemPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    path: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    title: PropTypes.string,
    shortTitle: PropTypes.string,
  });

  DashboardNav.propTypes = {
    leftButtons: PropTypes.arrayOf(navItemPropType),
    rightButtons: PropTypes.arrayOf(navItemPropType),
    NavLink: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.element,
    ]).isRequired,
  };

  DashboardNav.defaultProps = {
    leftButtons: false,
    rightButtons: false,
  };

  return DashboardNav;
}
