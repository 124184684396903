import React from 'react';
import PropTypes from 'prop-types';

import CommunicationPanel from '../../components/CommunicationPanel';
//
const LayoutForDashboard = ({ children }) => (
  <div className="page-wrapper dashboard">
    {children}
    <CommunicationPanel />
  </div>
);

LayoutForDashboard.propTypes = {
  children: PropTypes.node,
};

LayoutForDashboard.defaultProps = {
  children: undefined,
};

export default LayoutForDashboard;
