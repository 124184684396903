import PropTypes from 'prop-types';

import './DashboardAuth.scss';


export default function makeAuthPage(React) {
  const DashboardAuth = ({
    title,
    children,
    footer,
  }) => (
    <div className="mini-auth-page">
      <div className="mini-auth-page__header">
        <a href="/">
          <img
            className="mini-auth-page__logo"
            src="/img/logo-mini.svg"
            alt="TakeAwayEssay.com"
            width="170"
            height="45.95"
          />
        </a>
      </div>
      <div className="mini-auth-page__body">
        <div className="auth-block">
          <div className="auth-block__inner-wrapper">
            <div className="auth-block__auth-pane">
              <div className="auth-block__title">
                {title}
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
      {footer}
    </div>
  );

  DashboardAuth.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    footer: PropTypes.element.isRequired,
  };

  DashboardAuth.defaultProps = {
    title: undefined,
    children: undefined,
  };

  return DashboardAuth;
}
