import PropTypes from 'prop-types';

import './DashboardTopHeader.scss';


export default function makeDashboardTopHeader(React) {
  const DashboardTopHeader = ({
    userInfoProps,
    UserBlock,
  }) => (
    <header className="rc-header">
      <div className="Page__width rc-header__inner-container">
        <div className="rc-header__brand">
          <a href="/" className="rc-header__logo" title="Go to Home Page">
            <img
              src="/img/logo-mini.svg"
              width="131"
              height="36"
              className="rc-header__logo__img"
              alt="Takeawayessay.com"
            />
          </a>
        </div>
        <div className="rc-header__phone-block">
          Call free:
          {' '}
          <a href="tel:+18552605455" className="rc-header__phone-number">
            1-855-260-5455
          </a>
        </div>
        <div className="rc-header__user-info" {...userInfoProps}>
          <UserBlock />
        </div>
      </div>
    </header>
  );

  DashboardTopHeader.propTypes = {
    userInfoProps: PropTypes.shape({}),
    UserBlock: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.element,
    ]).isRequired,
  };

  DashboardTopHeader.defaultProps = {
    userInfoProps: {},
  };

  return DashboardTopHeader;
}
