import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../layouts/Dashboard';
import Dashboard from '../../components/Dashboard';

const DashboardPage = () => (
  <Layout>
    <Helmet
      meta={[
        { name: 'robots', content: 'noindex, nofollow' },
      ]}
    />
    <Dashboard />
  </Layout>
);

export default DashboardPage;
