import React, { Component } from 'react';

import { app } from '../../utils/cwt';

import { makeUserBlockButton, makeUserBlockWhois } from '../UserBlock';

import makeAuthPage from './DashboardAuth';
import makeDashboardNav from './DashboardNav';
import makeDashboardTopHeader from './DashboardTopHeader';
import footerOptions from './DashboardFooter';


const APP_ID = '___dashboard';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.app = app();
  }

  componentDidMount() {
    this.app.init({
      target: this.appElement,
      rcappComponents: {
        makeUserBlockButton,
        makeUserBlockWhois,
        makeAuthPage,
        makeDashboardNav,
        makeDashboardTopHeader,
        footerOptions,
      },
      app: 'full-app',
      useRouter: true,
    });
    const el = document.createElement('meta');
    el.name = 'robots';
    el.content = 'noindex, nofollow';
    document.head.insertAdjacentElement('beforeend', el);
  }

  componentWillUnmount() {
    this.app.destroy();
  }

  render() {
    return (

      <div id={APP_ID} ref={(ref) => { this.appElement = ref; }} />
    );
  }
}

export default Dashboard;
